import { Observable } from 'rxjs';

import { COMPANY_BASE_URL, useBackendHttpService } from '~/api';

import { FileDraftUploadResponse } from './files.dto';

export function uploadFileDraft$(companyId: number, request: FormData): Observable<FileDraftUploadResponse> {
	const { http } = useBackendHttpService();
	return http.post(`${COMPANY_BASE_URL}/${companyId}/files/draft`, request, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
}
