import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useObservable } from '@silae/composables';
import { Optional } from '@silae/helpers';
import {
	CONGES_PAYES_CODE,
	LeaveDaysCreationRequest,
	LeaveDaysDTO,
	LeaveDaysHistory,
	LeaveDaysUpdateRequest,
	createLeaveDays$,
	createLeaveDaysAsAdmin$,
	createLeaveDaysAsManager$,
	fetchSplittingDaysConfiguration$,
	modifyLeaveDays$,
	modifyLeaveDaysAsAdmin$,
	modifyLeaveDaysAsManager$
} from '~/api';
import { useDialogs, useSelfManager } from '~/composables';
import { useCompanySelectionStore, useRolesStore } from '~/stores';
import { ISODateAsLocalDate, prettyEmployeeName, splitHoursAndMinutes } from '~/utils';

import { PrettyLeaveDays } from '../leave-days.domain';

export function useLeaveDays(): {
	getPrettyLabel: (label: string | undefined) => string;
	asPrettyLeaveDays: (leaveDays: LeaveDaysDTO) => PrettyLeaveDays;
	getPrettyLeaveDaysPeriod: (leaveDays: LeaveDaysDTO) => string;
	getPrettyLeaveDaysDuration: (leaveDays: LeaveDaysDTO) => string;
	getPrettyLeaveDaysHistoryPeriod: (history: LeaveDaysHistory) => string;
	getPrettyLeaveDaysHistoryDuration: (history: LeaveDaysHistory) => string;
} {
	const { t } = useI18n();

	// Must be in a composable because needs the composable useI18n
	const asPrettyLeaveDays = (leaveDays: LeaveDaysDTO): PrettyLeaveDays => {
		return {
			...leaveDays,
			selected: false,
			label: getPrettyLabel(leaveDays.label),
			employeeName: prettyEmployeeName(leaveDays.employeeFirstName, leaveDays.employeeLastName),
			prettyStart: getPrettyDate(leaveDays.start),
			prettyEnd: getPrettyDate(leaveDays.end),
			prettyDuration: getPrettyLeaveDaysDuration(leaveDays)
		};
	};

	const getPrettyLabel = (label: string | undefined): string => {
		return label ? label : t('common.fields.no_type');
	};

	const getPrettyDate = (ISO: string): string => ISODateAsLocalDate(ISO, DateTime.DATE_SHORT);

	/**
	 * Ex: Journée, 0h45, ...
	 */
	const getPrettyLeaveDaysDuration = (leaveDays: LeaveDaysDTO): string => {
		if (leaveDays.isHalfDay) {
			return t('common.fields.half_day');
		}
		if (leaveDays.isHours) {
			const { hours, minutes } = splitHoursAndMinutes(leaveDays.hours);
			return t('common.fields.hours_minutes_value', { hours, minutes });
		}
		return t('common.fields.short_day');
	};

	/**
	 * Ex: du 13/02/2024 au 15/02/2024, le 17/02/2024 - 0h45, ...
	 */
	const getPrettyLeaveDaysPeriod = (leaveDays: LeaveDaysDTO): string => {
		if (leaveDays.isHalfDay) {
			return t('common.fields.period.half_day', { date: getPrettyDate(leaveDays.start) });
		}
		if (leaveDays.isHours) {
			return t('common.fields.period.hours', {
				date: getPrettyDate(leaveDays.start),
				duration: getPrettyLeaveDaysDuration(leaveDays)
			});
		}

		const start = DateTime.fromISO(leaveDays.start);
		const end = DateTime.fromISO(leaveDays.end);
		const diffInDays = end.diff(start, 'days').days;
		if (diffInDays >= 1) {
			return t('common.fields.period.many_days', {
				dateStart: getPrettyDate(leaveDays.start),
				dateEnd: getPrettyDate(leaveDays.end)
			});
		}
		return t('common.fields.period.one_day', { date: getPrettyDate(leaveDays.start) });
	};

	/**
	 * Ex: Journée, 0h45, ...
	 */
	const getPrettyLeaveDaysHistoryDuration = (history: LeaveDaysHistory): string => {
		if (history.isHalfDay) {
			return t('common.fields.half_day');
		}
		if (history.isHours) {
			const { hours, minutes } = splitHoursAndMinutes(history.hours);
			return t('common.fields.hours_minutes_value', { hours, minutes });
		}
		return t('common.fields.short_day');
	};

	/**
	 * Ex: du 13/02/2024 au 15/02/2024, le 17/02/2024 - 0h45, ...
	 */
	const getPrettyLeaveDaysHistoryPeriod = (history: LeaveDaysHistory): string => {
		if (history.isHalfDay) {
			return t('common.fields.period.half_day', { date: getPrettyDate(history.start) });
		}
		if (history.isHours) {
			return t('common.fields.period.hours', {
				date: getPrettyDate(history.start),
				duration: getPrettyLeaveDaysHistoryDuration(history)
			});
		}

		const start = DateTime.fromISO(history.start);
		const end = DateTime.fromISO(history.end);
		const diffInDays = end.diff(start, 'days').days;
		if (diffInDays >= 1) {
			return t('common.fields.period.many_days', {
				dateStart: getPrettyDate(history.start),
				dateEnd: getPrettyDate(history.end)
			});
		}
		return t('common.fields.period.one_day', { date: getPrettyDate(history.start) });
	};

	return {
		asPrettyLeaveDays,
		getPrettyLabel,
		getPrettyLeaveDaysPeriod,
		getPrettyLeaveDaysDuration,
		getPrettyLeaveDaysHistoryDuration,
		getPrettyLeaveDaysHistoryPeriod
	};
}

export function useSplitDaysConfirmationModal(): {
	showSplitDaysConfirmationIfNeeded: (req$: Observable<void>, typeCode: string) => Observable<void>;
} {
	const { t } = useI18n();
	const { isEmployee } = storeToRefs(useRolesStore());
	const { trigger } = useObservable();
	const { warning } = useDialogs();
	const { employeeCompanyId } = storeToRefs(useCompanySelectionStore());

	const _showSplitDaysConfirmationIfNeeded = (request$: Observable<void>, typeCode: string) => {
		if (isEmployee.value && typeCode === CONGES_PAYES_CODE) {
			return fetchSplittingDaysConfiguration$(employeeCompanyId.value).pipe(
				switchMap(showSplitDaysDialog => {
					if (showSplitDaysDialog) {
						warning({
							title: t('leave_days.split_days.dialog_title'),
							alertTitle: t('leave_days.split_days.dialog_alert_title'),
							alertText: t('leave_days.split_days.dialog_alert_text'),
							actionBtn: {
								label: t('common.buttons.confirm'),
								action: () => trigger(request$)
							},
							closeBtnLabel: t('common.buttons.cancel')
						});
						return EMPTY;
					} else {
						return request$;
					}
				})
			);
		} else {
			return request$;
		}
	};

	return {
		showSplitDaysConfirmationIfNeeded: _showSplitDaysConfirmationIfNeeded
	};
}

export function useLeaveDaysOperations(
	showSplitDaysConfirmationIfNeeded?: (request$: Observable<void>, typeCode: string) => Observable<void>,
	leaveDays?: Ref<Optional<LeaveDaysDTO>>
): {
	createLeaveDays$: (request: LeaveDaysCreationRequest) => Observable<void>;
	modifyLeaveDays$: (request: LeaveDaysUpdateRequest) => Observable<void>;
} {
	const { isManager, isAdmin } = storeToRefs(useRolesStore());

	const companyId: Ref<Optional<number>> = ref();
	const { isSelfManager } = useSelfManager(companyId, leaveDays);

	const _createLeaveDays$: (request: LeaveDaysCreationRequest) => Observable<void> = (request: LeaveDaysCreationRequest) => {
		companyId.value = request.companyId;

		let request$: Observable<void>;
		if (isAdmin.value) {
			request$ = createLeaveDaysAsAdmin$(request);
		} else if (isManager.value || isSelfManager.value) {
			request$ = createLeaveDaysAsManager$(request);
		} else {
			request$ = createLeaveDays$(request);
		}

		return showSplitDaysConfirmationIfNeeded?.(request$, request.typeCode) ?? request$;
	};

	const _modifyLeaveDays$: (request: LeaveDaysUpdateRequest) => Observable<void> = (request: LeaveDaysUpdateRequest) => {
		companyId.value = request.companyId;

		let request$: Observable<void>;
		if (isAdmin.value) {
			request$ = modifyLeaveDaysAsAdmin$(request);
		} else if (isManager.value || isSelfManager.value) {
			request$ = modifyLeaveDaysAsManager$(request);
		} else {
			request$ = modifyLeaveDays$(request);
		}

		return showSplitDaysConfirmationIfNeeded?.(request$, request.typeCode) ?? request$;
	};

	return {
		createLeaveDays$: _createLeaveDays$,
		modifyLeaveDays$: _modifyLeaveDays$
	};
}
